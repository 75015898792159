/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_language_detection_detect_language_post } from '../models/Body_language_detection_detect_language_post';
import type { Body_transcribe_asr_post } from '../models/Body_transcribe_asr_post';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EndpointsService {

    /**
     * Transcribe
     * @param formData
     * @param method
     * @param task
     * @param language
     * @param initialPrompt
     * @param encode Encode audio first through ffmpeg
     * @param output
     * @returns any Successful Response
     * @throws ApiError
     */
    public static transcribeAsrPost(
        formData: Body_transcribe_asr_post,
        method: 'openai-whisper' | 'faster-whisper' = 'openai-whisper',
        task: 'transcribe' | 'translate' = 'transcribe',
        language?: 'af' | 'am' | 'ar' | 'as' | 'az' | 'ba' | 'be' | 'bg' | 'bn' | 'bo' | 'br' | 'bs' | 'ca' | 'cs' | 'cy' | 'da' | 'de' | 'el' | 'en' | 'es' | 'et' | 'eu' | 'fa' | 'fi' | 'fo' | 'fr' | 'gl' | 'gu' | 'ha' | 'haw' | 'he' | 'hi' | 'hr' | 'ht' | 'hu' | 'hy' | 'id' | 'is' | 'it' | 'ja' | 'jw' | 'ka' | 'kk' | 'km' | 'kn' | 'ko' | 'la' | 'lb' | 'ln' | 'lo' | 'lt' | 'lv' | 'mg' | 'mi' | 'mk' | 'ml' | 'mn' | 'mr' | 'ms' | 'mt' | 'my' | 'ne' | 'nl' | 'nn' | 'no' | 'oc' | 'pa' | 'pl' | 'ps' | 'pt' | 'ro' | 'ru' | 'sa' | 'sd' | 'si' | 'sk' | 'sl' | 'sn' | 'so' | 'sq' | 'sr' | 'su' | 'sv' | 'sw' | 'ta' | 'te' | 'tg' | 'th' | 'tk' | 'tl' | 'tr' | 'tt' | 'uk' | 'ur' | 'uz' | 'vi' | 'yi' | 'yo' | 'zh',
        initialPrompt?: string,
        encode: boolean = true,
        output: 'txt' | 'vtt' | 'srt' | 'tsv' | 'json' = 'txt',
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/asr',
            query: {
                'method': method,
                'task': task,
                'language': language,
                'initial_prompt': initialPrompt,
                'encode': encode,
                'output': output,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Language Detection
     * @param formData
     * @param method
     * @param encode Encode audio first through ffmpeg
     * @returns any Successful Response
     * @throws ApiError
     */
    public static languageDetectionDetectLanguagePost(
        formData: Body_language_detection_detect_language_post,
        method: 'openai-whisper' | 'faster-whisper' = 'openai-whisper',
        encode: boolean = true,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/detect-language',
            query: {
                'method': method,
                'encode': encode,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
