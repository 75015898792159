import React, { useState } from 'react';
import { SiObsidian } from 'react-icons/si';
import { BsClipboard, BsFiletypeHtml, BsFiletypeMd, BsPlus, BsX } from 'react-icons/bs';


type StandupItem = {
  id: number;
  question: string;
  placeholder: string;
  answers: string[];
};

export const DailyStandup: React.FC = () => {
  const todaysDateStr = new Date().toISOString().substring(0, 10);

  const [standup, setStandup] = useState<StandupItem[]>([
    { id: 1, question: 'What did I do yesterday?', placeholder: "Enter yesterday's work", answers: [] },
    { id: 2, question: 'What will I do today?', placeholder: "Enter today's work", answers: [] },
    { id: 3, question: 'Are there any blockers?', placeholder: "Enter blockers", answers: [] },
    { id: 4, question: 'What is the impact of my work today?', placeholder: "Enter impact", answers: [] },
    { id: 5, question: 'What are my big picture goals?', placeholder: "Enter goals", answers: [] },
  ]);

  const handleAddAnswer = (id: number, answer: string) => {
    setStandup((prevStandup) =>
      prevStandup.map((item) =>
        item.id === id ? { ...item, answers: [...item.answers, answer] } : item
      )
    );
  };

  const handleRemoveAnswer = (id: number, index: number) => {
    setStandup((prevStandup) =>
      prevStandup.map((item) =>
        item.id === id
          ? { ...item, answers: item.answers.filter((_, i) => i !== index) }
          : item
      )
    );
  };

  const handleActionToClipboardMarkdown = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const noteContent = standup
      .map((item) => `${item.question}\n${item.answers.map((answer) => `- ${answer}`).join('\n')}`)
      .join('\n');

    const textArea = document.createElement('textarea');
    textArea.innerHTML = noteContent;
    textArea.style.position = 'fixed';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
  };

  const handleActionToClipboardHtml = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let content = standup
      .map(
        (item) =>
          `<p><span>${item.question}</span><ul style="list-style-type:disc;">${item.answers
            .map((answer) => `<li>${answer}</li>`)
            .join('')}</ul></p>`
      )
      .join('');
    // content = `<h2>${todaysDateStr}</h2> ${content}`;

    const textArea = document.createElement('div');
    textArea.innerHTML = content;
    textArea.style.position = 'fixed';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);

    var range = document.createRange();
    range.selectNode(textArea);
    window.getSelection()!.removeAllRanges();
    window.getSelection()!.addRange(range);
    document.execCommand('copy');
    window.getSelection()!.removeAllRanges();

    document.body.removeChild(textArea);
  };

  const handleActionToObsidian = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Create the note content as a string
    const noteContent = standup
      .map((item) => `${item.question}\n\n${item.answers.map((answer) => `- ${answer}`).join('\n')}`)
      .join('\n\n---\n\n');

    // Create the Obsidian URI
    const encodedNoteContent = encodeURIComponent(noteContent);
    const encodedVaultName = encodeURIComponent('Vault');
    const encodedNoteName = encodeURIComponent(`/Standup/${todaysDateStr}`);
    const obsidianUri = `obsidian://new?vault=${encodedVaultName}&name=${encodedNoteName}&content=${encodedNoteContent}`;

    // Try to open the Obsidian URI using the Web API
    const success = await new Promise<boolean>((resolve) => {
      const frame = document.createElement('iframe');
      frame.src = obsidianUri;
      frame.style.display = 'none';
      frame.onload = () => {
        document.body.removeChild(frame);
        resolve(true);
      };
      frame.onerror = () => {
        document.body.removeChild(frame);
        resolve(false);
      };
      document.body.appendChild(frame);
    });

    if (!success) {
      console.log("failed on promise (obsidian)")
      // Handle error
    }
  };

  return (
    <div className="flex flex-col space-y-4 m-4">
      <ul className="space-y-4">
        {standup.map((item) => (
          <li key={item.id}>
            <div className="font-bold">{item.question}</div>
            <ul className="list-none list-inside">
              {item.answers.map((answer, index) => (
                <li key={index} className="">
                  <div className="flex items-center space-x-2">
                    {/* <span>•</span> */}
                    <button
                      type="button"
                      className="text-red-500 hover:bg-red-100 hover:text-red-800 rounded-sm focus:outline-none"
                      onClick={() => handleRemoveAnswer(item.id, index)}
                    >
                      <BsX />
                    </button>
                    <span className="flex-grow">{answer}</span>
                  </div>
                </li>
              ))}
              <li className="">
                <div className="flex items-center space-x-2">
                  {/* <span>•</span> */}
                  <BsPlus />
                  <input
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault();

                        // @ts-ignore
                        if (event.target.value.length === 0) {
                          return
                        }

                        // @ts-ignore
                        handleAddAnswer(item.id, event.target.value);
                        // @ts-ignore
                        event.target.value = '';
                      }
                    }}
                    className="shadow-sm focus:border-none focus:ring-none focus:outline-none mt-1 block w-full text-sm"
                    placeholder={item.placeholder}
                  />
                </div>
              </li>
            </ul>
          </li>
        ))}
      </ul>
      <div className='space-x-2'>
        <div className="tooltip" data-tip="Create Note in Obsidian">
          <button
            type="submit"
            className="inline-flex items-center p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            // @ts-ignore
            onClick={handleActionToObsidian}
          >
            <SiObsidian />
          </button>
        </div>
        <div className="tooltip" data-tip="Copy Markdown to Clipboard">
          <button
            type="submit"
            className="space-x-2 inline-flex items-center p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            // @ts-ignore
            onClick={handleActionToClipboardMarkdown}
          >
            <BsClipboard />
            <BsFiletypeMd />
          </button>
        </div>
        <div className="tooltip" data-tip="Copy HTML to Clipboard">
          <button
            type="submit"
            className="space-x-2 inline-flex items-center p-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            // @ts-ignore
            onClick={handleActionToClipboardHtml}
          >
            <BsClipboard />
            <BsFiletypeHtml />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DailyStandup;
