import React from 'react';
import { DailyStandup } from './DailyStandup';
import { Pomodoro } from './Pomodoro';
import { Transcribe } from './Transcribe';

const App: React.FC = () => {
  return (
    <div className='space-4'>
      <div className='card inline-block w-full max-w-xl'>
        <div className='card-body'>
          <DailyStandup />
        </div>
      </div>
      <div className='card inline-block w-full max-w-xl'>
        <div className='card-body'>
          <Pomodoro />
        </div>
      </div>
      <div className='card inline-block w-full max-w-xl'>
        <div className='card-body'>
          <Transcribe />
        </div>
      </div>
    </div>
  )
}
export default App;
