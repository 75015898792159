import React, { useState, useCallback } from 'react';
import { BsPauseFill, BsFillRecordFill } from 'react-icons/bs';
import {EndpointsService} from "../asr/generated";

export const Transcribe: React.FC = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [recordingTime, setRecordingTime] = useState(0);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>();
    const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
    const [recordingBlob, setRecordingBlob] = useState<Blob>();
    const [transcribeText, setTranscribeText] = useState<string>();
    
    const _startTimer = () => {
        const interval = setInterval(() => {
            setRecordingTime((time) => time + 1);
            }, 1000);
        setTimerInterval(interval);
    };

    const _stopTimer = () => {
        timerInterval != null && clearInterval(timerInterval);
        setTimerInterval(undefined);
    };
    
    const startRecording = useCallback(() => {
        if (timerInterval != null) return;

        navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
          setIsRecording(true);
          const recorder: MediaRecorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);
          recorder.start();
          _startTimer();

          recorder.addEventListener("dataavailable", (event) => {
              setRecordingBlob(event.data);
              recorder.stream.getTracks().forEach((t) => t.stop());
              setMediaRecorder(null);
          });
      })
      .catch((err) => console.log(err));
        }, [timerInterval]);
    
    const stopRecording = () => {
        mediaRecorder?.stop();
        _stopTimer();
        setRecordingTime(0);
        setIsRecording(false);
        transcribeRecording();
    };
    
    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };
    
    const transcribeRecording = () => {
        EndpointsService.transcribeAsrPost(
            {audio_file: recordingBlob!},
            "faster-whisper",
            "transcribe",
            "en",
            undefined,
            false,
            "txt"
        ).then((result) => {
            console.log(result)
            setTranscribeText(result);
        });
    };
    
    return (
        <div className={"border border-gray-800 border-solid p-2 grid gap-2"}>
            <div className={"flex gap-2 items-center"}>
                <button
                    className="btn btn-primary h-full"
                    // @ts-ignore
                    onClick={toggleRecording} >
                    {isRecording ? (<BsPauseFill />) : (<BsFillRecordFill />) }
                </button>
                <div className={""}>
                    {recordingTime}
                </div>
            </div>
            <div className={"bg-gray-100 w-full p-2"}>
                <code>
                    {transcribeText}
                </code>
            </div>
        </div>
    )
}