import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BsCircle, BsCheckCircle, BsPlayCircle, BsXCircle, BsStopCircle, BsPlusLg, BsAlarmFill } from 'react-icons/bs';
import classNames from 'classnames';
import ReactHowler from 'react-howler';
import Push from 'push.js';

type Task = {
  id: string;
  title: string;
  completed: boolean;
  pomodoroTime: number;
  timeSpent: number;
};

const initialTasks: Task[] = [];

enum PomodoroMode {
  FOCUS = 'Focus',
  SHORT_BREAK = 'Short Break',
  LONG_BREAK = 'Long Break',
}

function createNotification(title: string, body: string) {
  Push.create(title, {
    body: body,
  });
}

export const Pomodoro: React.FC = () => {
  const [focusTime, setFocusTime] = useState(25);
  const [shortBreakTime, setShortBreakTime] = useState(5);
  const [longBreakTime, setLongBreakTime] = useState(15);
  const [timeLeft, setTimeLeft] = useState(focusTime * 60);
  const [isRunning, setIsRunning] = useState(false);
  const [isAlarmActive, setIsAlarmActive] = useState(false);
  const [mode, setMode] = useState(PomodoroMode.FOCUS);
  const [activeTaskId, setActiveTaskId] = useState<string | null>(null);
  const [tasks, setTasks] = useState<Task[]>(initialTasks);

  const handleDragEnd = (result: any) => {
    if (!result.destination)
      return;

    const items = Array.from(tasks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setTasks(items);
  };

  const handleAddTask = () => {
    setTasks([
      ...tasks,
      {
        id: Math.random().toString(36).substring(7),
        title: '',
        completed: false,
        pomodoroTime: 25,
        timeSpent: 0,
      },
    ]);
  };

  const handleRemoveTask = (id: string) => {
    setTasks(tasks.filter((task) => task.id !== id));
  };

  const handleTitleChange = (id: string, title: string) => {
    setTasks(tasks.map((task) => task.id === id ? { ...task, title } : task));
  };

  const handlePomodoroTimeChange = (id: string, time: number) => {
    setActiveTaskId(id);
    setTasks(tasks.map((task) => task.id === id ? { ...task, pomodoroTime: time } : task));
  };

  const handlePomodoroUnfocus = () => {
    setActiveTaskId(null);
  };

  const handleCompleteTask = (id: string) => {
    setActiveTaskId(null);
    setTasks(tasks.map((task) => task.id === id ? { ...task, completed: !task.completed } : task));
  };

  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
    setMode(PomodoroMode.FOCUS);
    setTimeLeft(focusTime * 60);
  };

  const handleModeSwitch = (newMode: PomodoroMode) => {
    setIsRunning(false);
    setMode(newMode);
    switch (newMode) {
      case PomodoroMode.FOCUS:
        setTimeLeft(focusTime * 60);
        break;
      case PomodoroMode.SHORT_BREAK:
        setTimeLeft(shortBreakTime * 60);
        break;
      case PomodoroMode.LONG_BREAK:
        setTimeLeft(longBreakTime * 60);
        break;
    }
  };

  const handleAddTime = (amount: number) => {
    const newModeTime = mode === PomodoroMode.FOCUS
      ? focusTime + amount
      : mode === PomodoroMode.SHORT_BREAK
        ? shortBreakTime + amount
        : longBreakTime + amount;
    if (newModeTime > 0) {
      switch (mode) {
        case PomodoroMode.FOCUS:
          setFocusTime(newModeTime);
          break;
        case PomodoroMode.SHORT_BREAK:
          setShortBreakTime(newModeTime);
          break;
        case PomodoroMode.LONG_BREAK:
          setLongBreakTime(newModeTime);
          break;
      }
    }

    const newTimeLeft = timeLeft + amount * 60;
    if (newTimeLeft > 0) {
      setTimeLeft(newTimeLeft);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRunning) {
      interval = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 0) {

            setIsAlarmActive(true);

            createNotification("Pomodoro timer expired!", "Pomodoro timer expired!")

            setIsRunning(false);
            switch (mode) {
              case PomodoroMode.FOCUS:
                setMode(PomodoroMode.SHORT_BREAK);
                setTimeLeft(shortBreakTime * 60);
                break;
              case PomodoroMode.SHORT_BREAK:
                setMode(PomodoroMode.FOCUS);
                setTimeLeft(focusTime * 60);
                break;
              case PomodoroMode.LONG_BREAK:
                setMode(PomodoroMode.FOCUS);
                setTimeLeft(focusTime * 60);
                break;
            }
            return 0;
          }
          return prevTime - 1;
        });

        if (activeTaskId) {
          setTasks(tasks.map((task) => task.id === activeTaskId ? { ...task, timeSpent: task.timeSpent + 1 } : task));
        }
      }, 1000);
    } else {
      clearInterval(1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, mode, focusTime, shortBreakTime, longBreakTime, tasks, activeTaskId]);

  const activeTask = tasks.filter((value) => (value.id === activeTaskId))[0];

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className="flex flex-col space-y-4 m-4">
        <div className="pomodoro">
          <div className="flex flex-col items-center">
            <h2 className="text-2xl mb-4">
              <div className="btn-group">
                {
                  (Object.keys(PomodoroMode)).map((key: string) => {
                    const modeForKey = PomodoroMode[key as keyof typeof PomodoroMode];
                    return (
                      <button
                        key={key}
                        className={`btn ${mode === modeForKey ? 'btn-active' : ''}`}
                        onClick={() => handleModeSwitch(modeForKey)}
                      >
                        {modeForKey}
                      </button>
                    )
                  })
                }
              </div>
            </h2>
            <div className="flex space-x-1 gap-8">
              <div className="text-5xl font-bold">
                <span className="countdown font-mono">
                  {/* @ts-ignore */}
                  <span style={{ "--value": Math.floor(timeLeft / 60) }}></span>:
                  {/* @ts-ignore */}
                  <span style={{ "--value": timeLeft % 60 }}></span>
                </span>
              </div>
            </div>
            <div className="flex gap-2 mt-4">
              <div className="inline-flex flex-0 tooltip" data-tip="New Task">
                <button
                  className="items-center btn btn-primary h-full"
                  // @ts-ignore
                  onClick={handleAddTask} >
                  <BsPlusLg />
                </button>
              </div>
              <div className='inline-flex flex-1'></div>
              <div className='inline-flex flex-0 flex flex-col space-y-1'>
                <button
                  className={classNames("btn btn-xs", timeLeft <= 5 * 60 && "btn-disabled")}
                  onClick={() => handleAddTime(-5)}
                  disabled={timeLeft <= 5 * 60}
                >
                  -5m
                </button>
                <button
                  className={classNames("btn btn-xs", timeLeft <= 15 * 60 && "btn-disabled")}
                  onClick={() => handleAddTime(-15)}
                  disabled={timeLeft <= 15 * 60}
                >
                  -15m
                </button>
              </div>
              <div className='inline-flex flex-0'>
                <button
                  className="items-center btn btn-primary h-full"
                  onClick={isRunning ? handleStop : handleStart} >
                  {isRunning ? "Stop" : "Start"}
                </button>
              </div>
              <div className='inline-flex flex-0 flex flex-col space-y-1'>
                <button
                  className="btn btn-xs"
                  onClick={() => handleAddTime(5)}
                >
                  +5m
                </button>
                <button
                  className="btn btn-xs"
                  onClick={() => handleAddTime(15)}
                >
                  +15m
                </button>
              </div>
              <div className='inline-flex flex-1'></div>
              <div className="inline-flex flex-0 tooltip" data-tip="Toggle Alarm">
                <button
                  className="items-center btn btn-primary h-full gap-2"
                  // @ts-ignore
                  onClick={() => setIsAlarmActive(!isAlarmActive)}>
                  <BsAlarmFill />
                  {/* {isAlarmActive ? (<BsStopFill />) : (<BsPlayFill />)} */}
                </button>
              </div>
            </div>
          </div>
        </div>

        <Droppable droppableId="tasks">
          {(provided) => (
            <ul
              className="flex flex-col space-y-1"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {tasks.map((task, index) => (
                <Draggable
                  key={task.id}
                  draggableId={task.id}
                  index={index}
                >
                  {(provided) => (
                    <li
                      className={classNames(
                        'flex bg-white rounded-md flex justify-between items-center py-1 px-2',
                        task.completed && 'opacity-50 line-through',
                        activeTask === task && 'bg-blue-100'
                      )}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <input
                        type="text"
                        value={task.title}
                        onChange={(e) => handleTitleChange(task.id, e.target.value)}
                        placeholder="Task title"
                        className="inline-flex w-full mr-4 rounded-md border-gray-300 bg-transparent focus:border-none focus:ring-none focus:outline-none sm:text-sm" />
                      {/* <input
                        type="number"
                        value={task.pomodoroTime}
                        onChange={(e) => handlePomodoroTimeChange(
                          task.id,
                          Number(e.target.value)
                        )}
                        className="text-center inline-flex w-12 mr-4 rounded-md border-gray-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm" /> */}
                      <div className="inline-flex flex space-x-2 items-center align-middle">
                        <div className="inline-flex tooltip" data-tip={task.id === activeTaskId ? "Unfocus" : "Focus"}>
                          {
                            task.id === activeTaskId ?
                              (
                                <button
                                  className="text-gray-800 hover:text-gray-700 focus:outline-none"
                                  onClick={() => handlePomodoroUnfocus()}>
                                  <BsStopCircle />
                                </button>
                              ) : (
                                <button
                                  className="text-gray-800 hover:text-gray-700 focus:outline-none"
                                  onClick={() => handlePomodoroTimeChange(task.id, task.pomodoroTime)}>
                                  <BsPlayCircle />
                                </button>
                              )
                          }
                        </div>
                        <div className='inline-flex'>
                          <span className="countdown font-mono">
                            {/* @ts-ignore */}
                            <span style={{ "--value": Math.floor(task.timeSpent / 60) }}></span>:
                            {/* @ts-ignore */}
                            <span style={{ "--value": task.timeSpent % 60 }}></span>
                          </span>
                        </div>
                        <div className="inline-flex tooltip" data-tip={task.completed ? "Incomplete" : "Complete"}>
                          <button
                            className="text-gray-800 hover:text-gray-700 focus:outline-none"
                            onClick={() => handleCompleteTask(task.id)}
                          >
                            {task.completed ? <BsCheckCircle /> : <BsCircle />}
                          </button>
                        </div>
                        <div className="inline-flex tooltip" data-tip="Remove (double-click)">
                          <button
                            className="text-gray-800 hover:text-gray-700 focus:outline-none"
                            onDoubleClick={() => handleRemoveTask(task.id)}
                            disabled={task.id === activeTaskId}
                          >
                            <BsXCircle />
                          </button>
                        </div>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
        <div className='space-x-2 flex'>
          <div className='flex-1'></div>
        </div>
      </div>
      <ReactHowler
        src='/alarm.mp3'
        playing={isAlarmActive}
      />
    </DragDropContext>
  );
};
